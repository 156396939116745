// Add here the components that you want to bootstrap on PHP pages
import type { Type } from '@angular/core';

export interface LazyComponent {
  readonly index?: number;
  readonly selector: string;
  loadComponent(): Promise<Type<any>>;
  loadModule(): Promise<Type<any>>;
}

export const components: readonly LazyComponent[] = [
  {
    index: 0,
    selector: 'webapp-compat-tracking',
    loadComponent: () =>
      import('compat/compat-tracking.component').then(
        m => m.CompatTrackingComponent,
      ),
    loadModule: () =>
      import('compat/compat-tracking.module').then(m => m.CompatTrackingModule),
  },
  {
    index: 1,
    selector: 'webapp-compat-toast-alerts',
    loadComponent: () =>
      import('compat/compat-toast-alerts.component').then(
        m => m.CompatToastAlertsComponent,
      ),
    loadModule: () =>
      import('compat/compat-toast-alerts.module').then(
        m => m.CompatToastAlertsModule,
      ),
  },
  {
    index: 2,
    selector: 'webapp-compat-navigation',
    loadComponent: () =>
      import('compat/compat-navigation.component').then(
        m => m.CompatNavigationComponent,
      ),
    loadModule: () =>
      import('compat/compat-navigation.module').then(
        m => m.CompatNavigationModule,
      ),
  },
  {
    index: 2,
    selector: 'webapp-compat-navigation-empty',
    loadComponent: () =>
      import('compat/compat-navigation-empty.component').then(
        m => m.CompatNavigationEmptyComponent,
      ),
    loadModule: () =>
      import('compat/compat-navigation-empty.module').then(
        m => m.CompatNavigationEmptyModule,
      ),
  },
  {
    selector: 'webapp-compat-navigation-secondary-projects',
    loadComponent: () =>
      import(
        'app/navigation/navigation-secondary/navigation-secondary-projects.component'
      ).then(m => m.NavigationSecondaryProjectsComponent),
    loadModule: () =>
      import(
        'app/navigation/navigation-secondary/navigation-secondary-projects.module'
      ).then(m => m.NavigationSecondaryProjectsModule),
  },
  {
    selector: 'webapp-compat-navigation-secondary-about',
    loadComponent: () =>
      import(
        'app/navigation/navigation-secondary/navigation-secondary-about.component'
      ).then(m => m.NavigationSecondaryAboutComponent),
    loadModule: () =>
      import(
        'app/navigation/navigation-secondary/navigation-secondary-about.module'
      ).then(m => m.NavigationSecondaryAboutModule),
  },
  {
    selector: 'webapp-compat-navigation-secondary-contests',
    loadComponent: () =>
      import(
        'app/navigation/navigation-secondary/navigation-secondary-contests.component'
      ).then(m => m.NavigationSecondaryContestsComponent),
    loadModule: () =>
      import(
        'app/navigation/navigation-secondary/navigation-secondary-contests.module'
      ).then(m => m.NavigationSecondaryContestsModule),
  },
  {
    selector: 'webapp-compat-navigation-secondary-community',
    loadComponent: () =>
      import(
        'app/navigation/navigation-secondary/navigation-secondary-community.component'
      ).then(m => m.NavigationSecondaryCommunityComponent),
    loadModule: () =>
      import(
        'app/navigation/navigation-secondary/navigation-secondary-community.module'
      ).then(m => m.NavigationSecondaryCommunityModule),
  },
  {
    selector: 'webapp-compat-navigation-secondary-profile',
    loadComponent: () =>
      import(
        'app/navigation/navigation-secondary/navigation-secondary-profile.component'
      ).then(m => m.NavigationSecondaryProfileComponent),
    loadModule: () =>
      import(
        'app/navigation/navigation-secondary/navigation-secondary-profile.module'
      ).then(m => m.NavigationSecondaryProfileModule),
  },
  {
    selector: 'webapp-compat-navigation-secondary-showcase',
    loadComponent: () =>
      import(
        'app/navigation/navigation-secondary/navigation-secondary-showcase.component'
      ).then(m => m.NavigationSecondaryShowcaseComponent),
    loadModule: () =>
      import(
        'app/navigation/navigation-secondary/navigation-secondary-showcase.module'
      ).then(m => m.NavigationSecondaryShowcaseModule),
  },
  {
    selector: 'webapp-compat-navigation-secondary-portfolio',
    loadComponent: () =>
      import(
        'app/navigation/navigation-secondary/navigation-secondary-portfolio.component'
      ).then(m => m.NavigationSecondaryPortfolioComponent),
    loadModule: () =>
      import(
        'app/navigation/navigation-secondary/navigation-secondary-portfolio.module'
      ).then(m => m.NavigationSecondaryPortfolioModule),
  },
  {
    selector: 'webapp-compat-navigation-secondary-local-jobs',
    loadComponent: () =>
      import(
        'app/navigation/navigation-secondary/navigation-secondary-local-jobs.component'
      ).then(m => m.NavigationSecondaryLocalJobsComponent),
    loadModule: () =>
      import(
        'app/navigation/navigation-secondary/navigation-secondary-local-jobs.module'
      ).then(m => m.NavigationSecondaryLocalJobsModule),
  },
  {
    selector: 'webapp-compat-navigation-secondary-finances',
    loadComponent: () =>
      import(
        'app/navigation/navigation-secondary/navigation-secondary-finances.component'
      ).then(m => m.NavigationSecondaryFinancesComponent),
    loadModule: () =>
      import(
        'app/navigation/navigation-secondary/navigation-secondary-finances.module'
      ).then(m => m.NavigationSecondaryFinancesModule),
  },
  {
    selector: 'webapp-compat-navigation-secondary-my-skills',
    loadComponent: () =>
      import(
        'app/navigation/navigation-secondary/navigation-secondary-my-skills.component'
      ).then(m => m.NavigationSecondaryMySkillsComponent),
    loadModule: () =>
      import(
        'app/navigation/navigation-secondary/navigation-secondary-my-skills.module'
      ).then(m => m.NavigationSecondaryMySkillsModule),
  },
  {
    selector: 'webapp-compat-navigation-secondary-hire-freelancers',
    loadComponent: () =>
      import(
        'app/navigation/navigation-secondary/navigation-secondary-hire-freelancers.component'
      ).then(m => m.NavigationSecondaryHireFreelancersComponent),
    loadModule: () =>
      import(
        'app/navigation/navigation-secondary/navigation-secondary-hire-freelancers.module'
      ).then(m => m.NavigationSecondaryHireFreelancersModule),
  },
  {
    selector: 'webapp-compat-navigation-secondary-help',
    loadComponent: () =>
      import(
        'app/navigation/navigation-secondary/navigation-secondary-help.component'
      ).then(m => m.NavigationSecondaryHelpComponent),
    loadModule: () =>
      import(
        'app/navigation/navigation-secondary/navigation-secondary-help.module'
      ).then(m => m.NavigationSecondaryHelpModule),
  },
  {
    selector: 'webapp-compat-messaging',
    loadComponent: () =>
      import('compat/compat-messaging.component').then(
        m => m.CompatMessagingComponent,
      ),
    loadModule: () =>
      import('compat/compat-messaging.module').then(
        m => m.CompatMessagingModule,
      ),
  },
  {
    selector: 'webapp-compat-modals',
    loadComponent: () =>
      import('compat/compat-modals.component').then(
        m => m.CompatModalsComponent,
      ),
    loadModule: () =>
      import('compat/compat-modals.module').then(m => m.CompatModalsModule),
  },
  {
    selector: 'webapp-compat-abtest',
    loadComponent: () =>
      import('compat/compat-abtest.component').then(
        m => m.CompatABTestComponent,
      ),
    loadModule: () =>
      import('compat/compat-abtest.module').then(m => m.CompatABTestModule),
  },
  {
    selector: 'webapp-compat-accessibility',
    loadComponent: () =>
      import('compat/compat-accessibility.component').then(
        m => m.CompatAccessibilityComponent,
      ),
    loadModule: () =>
      import('compat/compat-accessibility.module').then(
        m => m.CompatAccessibilityModule,
      ),
  },
  {
    selector: 'webapp-compat-threatmetrix',
    loadComponent: () =>
      import('compat/compat-threatmetrix.component').then(
        m => m.CompatThreatmetrixComponent,
      ),
    loadModule: () =>
      import('compat/compat-threatmetrix.module').then(
        m => m.CompatThreatmetrixModule,
      ),
  },
  {
    selector: 'webapp-compat-seo-navbar',
    loadComponent: () =>
      import('compat/compat-seo-navbar.component').then(
        m => m.CompatSeoNavbarComponent,
      ),
    loadModule: () =>
      import('compat/compat-seo-navbar.module').then(
        m => m.CompatSeoNavbarModule,
      ),
  },
  {
    selector: 'webapp-compat-give-get-footer',
    loadComponent: () =>
      import('compat/compat-give-get-footer.component').then(
        m => m.CompatGiveGetFooterComponent,
      ),
    loadModule: () =>
      import('compat/compat-give-get-footer.module').then(
        m => m.CompatGiveGetFooterModule,
      ),
  },
  {
    selector: 'webapp-compat-share-modal',
    loadComponent: () =>
      import('compat/compat-share-modal.component').then(
        m => m.CompatShareModalComponent,
      ),
    loadModule: () =>
      import('compat/compat-share-modal.module').then(
        m => m.CompatShareModalModule,
      ),
  },
  {
    selector: 'webapp-compat-ai-consultant',
    loadComponent: () =>
      import('compat/compat-ai-consultant.component').then(
        m => m.CompatAiConsultantComponent,
      ),
    loadModule: () =>
      import('compat/compat-ai-consultant.module').then(
        m => m.CompatAiConsultantModule,
      ),
  },
  {
    selector: 'webapp-compat-ato-form',
    loadComponent: () =>
      import('compat/compat-ato-form.component').then(
        m => m.CompatAtoFormComponent,
      ),
    loadModule: () =>
      import('compat/compat-ato-form.module').then(m => m.CompatAtoFormModule),
  },
];
